<!--
 * @Author: your name
 * @Date: 2021-01-15 17:01:20
 * @LastEditTime: 2021-02-03 11:25:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\moreEvents\promotionStatistics\LinkStatics.vue
-->
<template>
  <div>
    <el-dialog :modal="false" :visible.sync="link" center title="详情">
      <div>
        <el-form ref="form" :model="row">
          <el-form-item prop="accum_name" label="活动名称：">
            <el-input v-model="row.accum_name" disabled style="width: 120px" />
          </el-form-item>
          <el-form-item prop="create_at" label="起止时间：">
            <el-input v-model="row.create_at" disabled style="width: 220px" />
          </el-form-item>
        </el-form>
        <el-row>
          <el-table stripe :data="tableData">
            <el-table-column type="index" align="center"></el-table-column>
            <el-table-column
              v-for="(list, index) in colemd"
              :key="index"
              :label="list.label"
              :align="list.align"
              :prop="list.prop"
              width=""
            >
              <template #default="{ row }">
                <div
                  v-if="list.label == '单号'"
                  class="underline"
                  @click="handleOrderDetail(row)"
                >
                  {{ row[list.prop] }}
                </div>
                <div v-else>{{ row[list.prop] }}</div>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row type="flex" class="row-bg" justify="end">
          <el-pagination
            background
            :current-page="form.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary">打 印</el-button>
        <el-button @click="link = !link">关 闭</el-button>
      </div>
    </el-dialog>
    <saleOrderDetail ref="saleOrderDetail"></saleOrderDetail>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import saleOrderDetail from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'

  export default {
    components: {
      saleOrderDetail,
    },
    data() {
      return {
        link: false,
        url: {
          list: '/promoteAdmin/accum/form-detail',
        },
        total: 0,
        form: {
          id: '', //累计促销活动ID
          cust_id: '', //终端店ID
          pageNo: 1, //页码 默认1
          pageSize: 10, //每页几行 默认10
        },
        row: {
          accum_name: '',
          create_at: '',
        },
        tableData: [],
        colemd: [
          {
            label: '单号',
            align: 'center',
            prop: 'bill_code',
            width: '',
          },
          {
            label: '品牌',
            align: 'center',
            prop: 'brand_name',
            width: '',
          },
          {
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            label: '规格',
            align: 'center',
            prop: 'specs',
            width: '',
          },
          {
            label: '单位',
            align: 'center',
            prop: 'unit_name',
            width: '',
          },
          {
            label: '数量',
            align: 'center',
            prop: 'quantity',
            width: '',
          },
          {
            label: '金额',
            align: 'center',
            prop: 'goods_money',
            width: '',
          },
        ],
      }
    },
    methods: {
      handlerdata(row) {
        console.log(row)
        this.form.pageNo = 1
        this.form.pageSize = 10
        this.form.id = row.activity_id
        this.form.cust_id = row.cust_id
        this.handlerlist()
      },
      handlerchongzhi() {
        this.$refs['form'].resetFields()
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res)
            this.tableData = res.data.list
            this.total = res.totalCount
            this.row.accum_name = res.data.info.name
            this.row.create_at =
              res.data.info.start_time + '-' + res.data.info.end_time
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleOrderDetail(row) {
        this.$refs['saleOrderDetail'].isshowDialog = true
        this.$refs['saleOrderDetail'].id = row.order_id
      },
    },
  }
</script>

<style></style>
