<!--
 * @Author: 候怀烨
 * @Date: 2021-01-07 16:42:47
 * @LastEditTime: 2021-01-18 09:59:37
 * @LastEditors: Please set LastEditors
 * @Description: 累计促销统计
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\moreEvents\promotionStatistics\index.vue
-->
<template>
  <!-- NAME[epic=动销] 累计促销统计 -->
  <div style="padding: 20px">
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="id">
          <el-select
            v-model="form.id"
            placeholder="请选择累计促销活动"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Redeem"
              :key="index"
              :label="item.accum_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="user_id">
          <el-select
            v-model="form.user_id"
            placeholder="请选择业务员"
            style="width: 120px"
          >
            <el-option
              v-for="item in Salesman"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="keyword_goods">
          <el-input
            v-model="form.keyword_goods"
            style="width: 200px"
            placeholder="商品名称、助记码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="keyword_cust">
          <el-input
            v-model="form.keyword_cust"
            style="width: 200px"
            placeholder="客户名称、助记码
          "
          ></el-input>
        </el-form-item>
        <el-form-item prop="remark">
          <el-input
            v-model="form.remark"
            placeholder="备注"
            style="width: 120px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="handlerchongzhi">清 空</el-button>
        </el-form-item>
        <el-form-item></el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        stripe
        :data="tableData"
        :summary-method="getSummaries"
        show-summary
      >
        <el-table-column align="center" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in colemd"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
          <template #default="{ row, $index }">
            <div v-if="row.bill_code == '合计'"></div>
            <div v-else>{{ $index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        ></el-table-column>
        <el-table-column label="操作" fixed="right" align="center">
          <template #default="{ row }">
            <el-button type="text" @click="handlerDetails(row)">
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
      <link-statics ref="link"></link-statics>
    </el-row>
  </div>
</template>

<script>
  import LinkStatics from './components/LinkStatics.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      LinkStatics,
    },
    data() {
      return {
        total: 0,
        form: {
          id: '', //累计促销活动ID
          user_id: '', //员工ID
          keyword_goods: '', //产品名称/助记码/简拼
          keyword_cust: '', //店铺名称/助记码/简拼
          pageNo: 1, //页码 默认1
          pageSize: 10, //每页几行 默认10
          remark: '', //备注,
        },
        time: [],
        Redeem: [],
        Salesman: [],
        tableData: [],
        url: {
          Redeem: '/promoteAdmin/accum/index',
          list: '/promoteAdmin/accum/form',
          Salesman: '/baseAdmin/common/staff-option',
        },
        colemd: [
          {
            label: '活动名称',
            align: 'center',
            prop: 'accum_name',
            width: '',
          },
          {
            label: '活动时间',
            align: 'center',
            prop: 'create_at',
            width: '',
          },
          {
            label: '客户名称',
            align: 'center',
            prop: 'cust_name',
            width: '',
          },
          {
            label: '奖品',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            label: '规格',
            align: 'center',
            prop: 'specs',
            width: '',
          },
          {
            label: '单位',
            align: 'center',
            prop: 'unit_name',
            width: '',
          },
          {
            label: '奖品数量',
            align: 'center',
            prop: 'quantity',
            width: '',
          },
          {
            label: '奖品货值',
            align: 'center',
            prop: 'goods_money',
            width: '',
          },
          {
            label: '现金',
            align: 'center',
            prop: 'gift_amount',
            width: '',
          },
          {
            label: '备注',
            align: 'center',
            prop: 'info',
            width: '',
          },
        ],
        checkList: [
          '活动名称',
          '活动时间',
          '客户名称',
          '奖品',
          '规格',
          '单位',
          '奖品数量',
          '奖品货值',
          '现金',
          '备注',
        ],
        footer: {},
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.colemd.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    mounted() {
      this.handlerRedeem()
      this.handlerSalesman()
      this.handlerInquire()
    },
    methods: {
      handlerDetails(row) {
        this.$refs.link.link = true
        this.$refs.link.handlerdata(row)
      },
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '合计'
            return
          }

          sums[7] = this.footer.goods_num
          sums[8] = this.footer.goods_money
          sums[9] = this.footer.gift_amount

          // const values = data.map((item) => Number(item[column.property]))
          // if (!values.every((value) => isNaN(value))) {
          //   sums[index] = values.reduce((prev, curr) => {
          //     const value = Number(curr)
          //     if (!isNaN(value)) {
          //       return prev + curr
          //     } else {
          //       return prev
          //     }
          //   }, 0)
          //   sums[index] += ' 元'
          // } else {
          //   sums[index] = 'N/A'
          // }
        })

        return sums
      },
      handlerchongzhi() {
        this.$refs['form'].resetFields()
        this.handlerInquire()
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlerInquire() {
        this.form.pageNo = 1
        this.form.pageSize = 10
        this.handlerlist()
      },
      handlerchange(val) {
        console.log(val)
        if (val.length > 0) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      handlerRedeem() {
        postAction(this.url.Redeem, {})
          .then((res) => {
            console.log(res)
            this.Redeem = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerSalesman() {
        postAction(this.url.Salesman, {})
          .then((res) => {
            console.log(res)
            this.Salesman = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res)
            this.tableData = res.data.list
            this.footer = res.data.footer
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>

<style></style>
